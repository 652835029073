import { createSignal, onMount } from 'solid-js';

function App() {
  const [appConfig, setAppConfig] = createSignal({});

  // Fetch the config values when the component mounts
  onMount(async () => {
    try {
      const response = await fetch('/config.json');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const config = await response.json();
      setAppConfig(config);
      document.title = config.name
    } catch (error) {
      console.error("Failed to load config:", error);
    }
  });

  return (
    <div>
      API Documentation: {appConfig().backendUrl}/docs
    </div>
  );
}

export default App;
